import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";

import SEO from "./seo";
import HomepageContent from "../../content/homepage.json";

function Header() {
  return (
    <header id="header">
      <div className="logo">
        <img className="headshot" src="/images/headshot.jpg" />
      </div>
      <div className="content">
        <div className="inner">
          <h1>{HomepageContent.title}</h1>
          <p>{HomepageContent.description}</p>
        </div>
      </div>
      <nav>
        <ul>
          <li>
            <Link to="/gallery">Gallery</Link>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
          <li>
            <a href="/james-pepke-resume.pdf">Resume</a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

function Footer() {
  return (
    <footer id="footer">
      <section id="social">
        <a href="mailto:j.pepke@comcast.net" title="Send Jim an email">
          <i className="fas fa-envelope" aria-hidden="true"></i>
        </a>
        <a href="http://linkedin.com/in/jim-pepke" title="LinkedIn">
          <i className="fab fa-linkedin" aria-hidden="true"></i>
        </a>
        <a href="https://m.facebook.com/jim.pepke" title="Facebook">
          <i className="fab fa-facebook" aria-hidden="true"></i>
        </a>
      </section>
      <p className="copyright">
        &copy; JimPepke.com. Design: <a href="https://html5up.net">HTML5 UP</a>.
      </p>
    </footer>
  );
}

export default function Layout({
  // location,
  title,
  description,
  children
}) {
  return (
    <>
      <SEO title={title} description={description} />
      {children ? (
        <Helmet>
          <body className="is-article-visible" />
        </Helmet>
      ) : null}
      <div id="wrapper">
        {children ? null : <Header />}
        {children ? (
          <div id="main">
            <article className="active">
              {children}
              <Link to="/" className="close">
                Close
              </Link>
            </article>
          </div>
        ) : null}
        {children ? null : <Footer />}
      </div>
      <div id="bg" />
    </>
  );
}
